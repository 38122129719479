@use "./src/app/styles/defaults.module";

.sidebar {
  width: defaults.$sidebarWidth;
  /* height: calc(100vh - #{defaults.$navbarHeight}); */
  background-color: transparent; /*#141414; */
  border-left: 1px solid #3d3d3d;
  color: defaults.$textColor;
  padding: 0 .5rem .5rem .5rem;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  position: fixed;
  right: 0;
  top: defaults.$navbarHeight;
}

.sidebarHeader {
  margin-top: 1rem;

  > .sidebarTitle {
    font-size: 1.2em;
  }
}